import { navigate } from "gatsby";
import React, { useState, useEffect } from "react";

import DashboardNav from "../../../components/navbar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FAC_NAVBAR_LINKS } from "../../../global";
import DashboardHome from "../../../components/navbar";
import {
  RealmAppProvider,
  LogOutButton,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";

import OnboardingRedirector from "../../../components/facilitator/onboarding_redirector";
import { defaultPhoneInputValidation, validateName } from "../../../utils";
import styles from "../../../styles.css.ts";
import "./settings.css";
import { Footer } from "../../../components/footer";
import { Button } from "react-bootstrap";

const SettingsPage = () => {
  console.log("FAC_NAVBAR_LINKS", FAC_NAVBAR_LINKS);
  return (
    <>
      <div className="page">
        <DashboardNav
          link="/facilitator/dashboard"
          menuItems={FAC_NAVBAR_LINKS}
          settingsLink="/facilitator/dashboard/settings"
        />
        <br />
        <br />
        <section className="page-content" style={{ textAlign: "left" }}>
          <div className="settings-heading">
            <h2 className="settings-title">Settings</h2>
            <LogOutButton />
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <SettingsPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
